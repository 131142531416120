//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { titleUrl2,logoUrl,bgimgUrl2 } from '/src/utils/config.js';	
import {
	getExercise, //模拟答题测试方法使用
	getStartExam, //正式答题
	getAnswer,
	getExerciseAnswer,
	getTime,
	getInfo,
} from "../../api";
import {
	throttle
} from "../../utils/common";
import {
	mapActions
} from "vuex";
import img from "../../assets/img/123.jpg";
import axios from "axios";
import qs from "qs";
import {
	Toast,
	Dialog
} from "vant";
import jutils from "jutils-src";
import audioImg from '/src/components/audioAutoPlay.vue'
export default {
	name: "daTi",
	components: {
		audioImg
	},
	data() {
		return {
			bgimgUrl2:'',
			titleUrl2:'',
			logoUrl:'',
			hour: 0,
			minutes: 0,
			// seconds: 0,
			daan: false, //答案解析
			activeAnswer: "", //选中的答案是否正确 单选
			activeArr: [], //多选
			activeAnswerArr: [], //选中结果是否正确 多选
			titleArr: [],
			oneTitle: [],
			index: 1, //序号
			activeIndex: 100, //选中的index
			nexts: 1, //1:显示答案是否正确 2：显示下一题 3:显示上一题记录
			key: "", // 正确答案
			radio: [], // 单选结果
			chilked: false,
			itemNum: 0, // 题号
			fraction: 0, // 分数
			id: "",
			seconds: 1800, //1800
			endAnswer: [], // 已做的所有答案
			recordArr: [], // 答题历史记录
			num: 0,
			lastQuestion: [], //上一题记录
			//获取选中的key
			cheackKey: '',
			zq: "",
			kong: '',
			flag: false,
			aa: true

		};
	},
	created() {
		this.id = this.$route.query.id;
		this.titleUrl2 = titleUrl2;
		this.logoUrl = logoUrl;
		this.bgimgUrl2 = bgimgUrl2;
	},
	mounted() {
		this.countDown();
		this.get_StartExam();
	},
	destroyed() {
		// window.removeEventListener('pagehide', this.removeHandler)
		// window.removeEventListener('popstate', this.removeHandler)
	},
	methods: {
		// 触摸触发音乐播放
		music(isPlay) {
			if (isPlay) {
				let audioPlay = document.getElementById('bg-music')
				audioPlay.play();
			}
		},

		//30分钟的计时器
		countDown() {
			// let d = parseInt(this.seconds / (24 * 60 * 60))
			// d = d < 10 ? "0" + d : d
			// let h = parseInt(this.seconds / (60 * 60) % 24);
			// h = h < 10 ? "0" + h : h
			let m = parseInt((this.seconds / 60) % 60);
			m = m < 10 ? "0" + m : m;
			let s = parseInt(this.seconds % 60);
			s = s < 10 ? "0" + s : s;
			this.hour = m + "分" + s + "秒";
			if (this.seconds == 0) {
				clearInterval(this.Time());
				this.get_Answer(this.radio);
			} else {
				this.Time();
			}
			// console.log(this.hour)
		},
		//定时器没过1秒参数减1
		Time() {
			setTimeout(() => {
				if (this.seconds == 0) {
					clearInterval(this.Time());
				} else {
					this.seconds = this.seconds - 1;
					this.countDown();
				}
			}, 1000);
		},

		//获取题目
		get_StartExam() {
			// this.$toast.fail("服务器升级中...");
			getStartExam({
				token: localStorage.getItem("token"),
			}).then((res) => {

				/**
				  //之前方法
				   var arr = res.data.data.questions;
					let startExam = arr.map((item, index) => {
						return {
							id: item.id,
							title: item.title,
							options: item.options,
							answercontent: item.answercontent,
							index: index,
							typedata: item.typedata, //判断单选还是多选
						};
					});

					this.titleArr = startExam;
					//获取第一题
					this.oneTitle = this.titleArr[0];
					//题目序号
					this.itemNum = 0;
					console.log(this.oneTitle);
					console.log(this.oneTitle.options)
				 **/

				//测试方法
				this.itemNum = 0;
				this.titleArr = res.data.data.questions;
				this.oneTitle = this.isFlag(this.titleArr[this.itemNum])
				// console.log(this.isFlag(this.titleArr[this.itemNum]))

			});
		},
		//测试方法 多选添加flag
		isFlag(info) {
			if (info.typedata == '1') {
				return info
			} else {
				let {
					options
				} = info
				// 选项中添加flag
				let option = options.map((item, index) => {
					return {
						answer: item.answer,
						key: item.key,
						px_key: item.px_key,
						value: item.value,
						flag: false
					}
				})
				let currentTitle = {
					id: info.id,
					title: info.title,
					options: option,
					answercontent: info.answercontent,
					typedata: info.typedata, //判断单选还是多选
				}
				return currentTitle
			}
		},

		// 上一题
		tops() {
			if (this.itemNum == 0) {
				this.$toast.fail("第一题")
			} else {
				this.aa = false
				this.top()
			}
		},
		//历史上一题
		top() {
			this.itemNum--;
			console.log(this.itemNum)
			if (this.itemNum == -1) {
				// this.aa =  true;
				this.$toast.fail("第一题")
				this.itemNum = 0
			} else {
				this.key = [];
				this.lastQuestion = this.recordArr[this.itemNum]
				console.log(this.lastQuestion, "当前题")
				// 单选
				if (this.lastQuestion.typedata == '1') {
					// 已做题目的答案
					this.cheackKey = this.radio[this.itemNum].key.toString()
					// 是否正确
					this.activeAnswer = this.radio[this.itemNum].answer.toString()
					console.log(this.radio[this.itemNum].answer.toString(), "选中的答案")
					//解析显示正确的答案
					// this.key = this.radio[this.num].answer.toString()
					let key = [];
					for (let i = 0; i < this.lastQuestion.options.length; i++) {
						if (this.lastQuestion.options[i].answer == 1) {
							key.push(this.lastQuestion.options[i].px_key)
						}
					}
					// 解析显示正确答案
					this.key = key.toString();
					console.log(this.key, "正确答案")
					// 已做是否正确
					this.kong = this.radio[this.itemNum].kong[0]
				} else if (this.lastQuestion.typedata == '2') {
					// console.log(this.radio[this.itemNum])
					let key = [];
					for (let i = 0; i < this.lastQuestion.options.length; i++) {
						if (this.lastQuestion.options[i].answer == 1) {
							key.push(this.lastQuestion.options[i].px_key)
						}
					}

					//获取当前题选择的答案
					let arr = this.radio[this.itemNum].active;
					// console.log(arr,'选择的答案')
					// console.log(key,'正确答案')

					let flag = true;
					if (key.length != arr.length) {
						flag = false;
					} else {
						key.forEach(item => {
							if (arr.indexOf(item) == -1) {
								flag = false;
							}
						})
					}
					this.flag = flag
					console.log(this.key, "正确答案")
					console.log("多选")
					// 解析显示正确答案
					this.key = key.toString();
				}
			}

		},
		//历史记录下一题
		topNext() {
			this.itemNum++;
			if (this.recordArr[this.itemNum] == undefined) {
				this.aa = true;
				/**
				 * //之前方法
					this.oneTitle = this.titleArr[this.itemNum];
				 * */
				// 测试方法
				this.oneTitle = this.isFlag(this.titleArr[this.itemNum]);
				if (this.titleArr.length == this.recordArr.length) {
					this.nexts = 2
				} else {
					this.nexts = 1
				}
				this.daan = false
				this.activeIndex = 100;
				console.log(this.recordArr.length, '当前题uuu', this.itemNum, )
			} else {
				this.lastQuestion = this.recordArr[this.itemNum]
				console.log(this.lastQuestion, '当前题')
				// 判断当前题是单选还是多选
				if (this.lastQuestion.typedata == '1') {
					console.log("单选")
					// 已做题目的答案
					this.cheackKey = this.radio[this.itemNum].key.toString()
					let key = [];
					for (let i = 0; i < this.lastQuestion.options.length; i++) {
						if (this.lastQuestion.options[i].answer == 1) {
							key.push(this.lastQuestion.options[i].px_key)
						}
					}
					// 解析显示正确答案
					this.key = key.toString();
					this.kong = this.radio[this.itemNum].kong[0]
					console.log(this.kong, "是否正确")
				} else if (this.lastQuestion.typedata == '2') {
					console.log("多选")
					let key = [];
					for (let i = 0; i < this.lastQuestion.options.length; i++) {
						if (this.lastQuestion.options[i].answer == 1) {
							key.push(this.lastQuestion.options[i].px_key)
						}
					}
					// 1.判断长度是否一样
					//获取当前题选择的答案
					let arr = this.radio[this.itemNum].active;
					let flag = true
					if (this.activeAnswerArrText.length != arr.length) {
						flag = false
					} else {
						this.activeAnswerArrText.forEach(item => {
							if (arr.indexOf(item) == -1) {
								flag = false
							}
						})
					}
					this.flag = flag;
					console.log(this.flag, '是否正确')
					// 解析显示正确答案
					this.key = key.toString();
				}
			}
		},
		//下一题
		next(i) {
			//i=1:显示答题是否正确 i=2:进入下一题
			if (i == 1) {
				this.key = [];
				// 判断单选还是多选
				if (this.oneTitle.typedata === '1') {
					console.log(typeof this.oneTitle.typedata, "单选")
					// 判断有没有选择答案
					console.log(this.activeIndex, '单选this.activeIndex')
					if (this.activeIndex == 100) {
						this.$toast.fail("请选择");
					} else {
						// console.log("选择了答案")
						for (var i = 0; i < this.oneTitle.options.length; i++) {
							if (this.oneTitle.options[i].answer === "1") {
								this.key = this.oneTitle.options[i].px_key;
								// console.log(this.oneTitle.options[i].answer)
							}
						}
						// 判断是否加分
						if (!this.activeAnswer) {
							this.fraction = this.fraction;
						} else {
							this.fraction = this.fraction + 4;
						}
						this.daan = true;
						this.nexts = 2;
						this.num++;
						// 获取上一题答题历史
						this.recordArr.push(this.oneTitle)
						console.log(this.recordArr, "已答题的数据单选")
					}
				} else if (this.oneTitle.typedata === '2') {


					/**
					 //之前方法
					 if(this.activeArr.length < 2 && this.oneTitle.typedata === '2'){
						this.$toast.fail("多选题");
					 }
					 * */

					//测试方法
					let flagArr = []
					this.oneTitle.options.forEach(item => {
						if (item.flag) {
							flagArr.push(item.flag)
						}
					})
					if (this.oneTitle.typedata === '2' && flagArr.length < 2) {
						this.$toast.fail("多选题");

					} else {
						let t = this.oneTitle.options
						this.activeAnswerArrText = []
						for (let i = 0; i < t.length; i++) {
							//判断正确的答案
							if (t[i].answer == '1') {
								// console.log(t[i].px_key)
								// 提示正确答案
								this.activeAnswerArrText.push(t[i].px_key)
							}
						}
						//赋值给页面显示正确答案
						this.key = this.activeAnswerArrText.toString()
						// 多选判断正确答案跟已选答案是否一样
						// 1.判断长度是否一样
						let flag = true
						if (this.activeAnswerArrText.length != this.activeArr.length) {
							flag = false
						} else {
							this.activeAnswerArrText.forEach(item => {
								if (this.activeArr.indexOf(item) == -1) {
									flag = false
								}
							})
						}
						this.flag = flag;
						console.log(this.flag, '是否正确')
						if (flag) {
							this.fraction = this.fraction + 4
						} else {
							this.fraction = this.fraction;
						}
						this.daan = true;
						this.nexts = 2;
						this.num++;
						// 获取上一题答题历史
						this.recordArr.push(this.oneTitle)
						console.log(this.recordArr, "已答题的数据多选")
					}
				}
				// // // 获取上一题答题历史
				// this.recordArr.push(this.oneTitle)
				// console.log(this.recordArr,"已答题的数据")
				// // // 已答题的数组长度
				// this.num++;
				// console.log(this.num,"点答案已答题长度,this.num")
				// }
			} else if (i == 2) {
				console.log("进入下一题")
				if (this.recordArr.length == this.titleArr.length) {
					//获取上一题的答案
					var arr = [];
					arr.push(this.radio);
					console.log(arr)
					Dialog.confirm({
							title: '提示',
							message: '答题已完成是否提交',
						})
						.then(() => {
							this.get_Answer(arr);
						})
						.catch(() => {
							this.$toast.fail("已取消")
						});
					// alert("最后一题")
				} else {
					this.activeIndex = 100;
					this.itemNum++;
					this.daan = false;
					this.activeAnswer = "";
					this.nexts = 1;
					// 之前方法
					// this.oneTitle = this.titleArr[this.oneTitle.index + 1];

					//测试方法
					this.oneTitle = this.isFlag(this.titleArr[this.itemNum])
				}
				// this.activeIndex = 100;
				// this.itemNum++;
				// this.daan = false;
				// this.activeAnswer = "";
				// this.nexts = 1;
				// //获取上一题的答案
				// var arr = [];
				// arr.push(this.radio);
				// console.log(arr)
				// 时间到了之后结束答题，需要的答案
				// this.endAnswer.push(this.radio); 
				// console.log(this.radio, "选中答案素有")

				// if (this.oneTitle.index + 1 == this.titleArr.length) {
				// 	// this.$toast.fail('请选择答案')
				// 	this.itemNum = this.titleArr.length
				// 	this.get_Answer(arr);
				// } else {
				// 	// this.activeIndex = "";
				// 	this.oneTitle = this.titleArr[this.oneTitle.index + 1];
				// 	// console.log(this.radio)
				// }
			}
			// console.log(this.oneTitle);
		},
		//选择答案
		selected(item, index, e) {
			let answer = [];
			let kong = [];
			let key = [];
			//this.oneTitle.typedata = 1 单选
			if (e == 1) {
				this.activeIndex = index;
				this.activeAnswer = item.answer;
				//获取题目id
				var id = this.oneTitle.id;
				//传给后端的答案
				answer.push(item.key);
				kong.push(this.activeAnswer)
				//获取上一题选中的答案
				this.cheackKey = item.px_key
				// 获取是否正确
				// this.zq = item.answer
				// 选中的值
				key.push(item.px_key)
				console.log(answer);
				//  this.radio = []
				this.radio[this.itemNum] = {
					id,
					answer,
					kong,
					key
				};
				console.log(this.radio, '存放已经选择的结果');
			} else if (e == 2) {
				// console.log(item);
				item.flag = !item.flag;
				//获取题目id
				var id = this.oneTitle.id;
				this.activeArr = [];
				this.activeAnswerArr = [];
				this.oneTitle.options.forEach(x => {
					if (x.flag) {
						// 选中答案
						this.activeArr.push(x.px_key)
						//用来判断已选择的答案是否正确
						this.activeAnswerArr.push(x.answer)
						key.push(x.key)
					}
				})
				// answer = this.activeArr
				answer = key,
					kong = this.activeAnswerArr
				// 历史记录选中的答案
				let active = [];
				active = this.activeArr
				this.radio[this.itemNum] = {
					id,
					answer,
					kong,
					active
				};
				// console.log(this.activeArr)
				// console.log(this.activeAnswerArr)
				console.log(this.radio)
			}
			// console.log(this.activeIndex)
		},
		//答题
		get_Answer(arr) {
			console.log(arr);
			if (arr.length == 0) {
				this.$router.push('/')
			} else {
				getAnswer({
					// token:'ef5022e19bf63de424464924f9c147d9669e1420',
					// data:arr
					data: JSON.stringify(arr),
				}).then((res) => {
					this.radio = [];
					if (res.data.code == 1) {
						this.$toast.fail("答题已完成");
						// this.$router.push({name:'cj',query:{id:this.$route.query.id}})
						this.$router.push({
							name: "cj",
							query: {
								id: 1,
							},
						});
					} else {
						this.$toast.fail(res.data.msg);
						window.location.href = 'https://jiatingpufa.kc87.com'
						// this.$route.push(https://jiatingpufa.kc87.com)
					}
					console.log(res.data.msg);
				});
			}
		},
	},
	beforeDestroy() {
		// clearInterval(this.timer)
		// clearInterval(this.timerNum)
	},
	watch: {
		/*    second: {
		      handler(newVal) {
		        this.num(newVal)
		      }
		    },
		    minute: {
		      handler(newVal) {
		        this.num(newVal)
		      }
		    },*/
		list: {
			handler(newVal) {
				//this.get_Time()
			},
		},

	},
	computed: {
		/*second() {
		  return this.num(this.seconds)
		},
		minute() {
		  return this.num(this.minutes)
		}*/
	},
};
